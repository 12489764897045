<template>
  <div class="flex flex-col grow gap-2" :class="{ 'overflow-hidden': workbook && items.length }">
    <ImporterHeader
      v-if="workbook"
      :importHandler="importItems"
      :mapHandler="processItems"
      :resetHandler="reset"
      :hasItems="!!items.length"
      :canMap="!!Object.keys(columnsMapToField).length"
      :loading="loading"
    />
    <template v-if="loading">
      <div class="flex flex-col w-full max-w-7xl self-center gap-4">
        <div class="text-lg font-medium text-surface-700">{{ loadingStage }}</div>
        <div v-if="processing" class="text-sm">{{ processingStage }}</div>
        <ProgressBar :value="loadingProgress" :showValue="false" style="height: 6px" />
        <div class="text-sm px-4">
          <p>
            This process may take a long time depending on how many items you are
            processing/importing. Please be patient and do not navigate away or close this tab until
            complete, even if the progress bar appears to have stalled.
          </p>
        </div>
      </div>
    </template>
    <template v-else-if="!items.length">
      <ImportUploader
        v-if="!workbook"
        :templateDownloader="downloadImportTemplate"
        :upload-handler="uploadFileHandler"
        :exporter="exportItems"
      />
      <ImportMapper
        v-else-if="workbook"
        :workbook="workbook"
        :supportedFields="supportedFields"
        :keySet="mappingKeySet"
        v-model:selectedSheet="selectedSheet"
        v-model:columnsMapToField="columnsMapToField"
        v-model:keyField="keyField"
      >
        <template #warning>
          <warning v-if="globalVendorFieldFound">
            <p class="text-warning">
              <small>
                Vendor field(s) have been excluded since global items are not tied to specific
                vendors.
              </small>
            </p>
          </warning>
        </template>
      </ImportMapper>
    </template>
    <ImportTable v-else schema="cost_type" :columns="extendedFields" v-model:items="items">
      <template #extended="{ item, field, index, editCallback }">
        <FileList
          @input="(newValue) => editCallback({ field, index, newValue })"
          :value="item"
          class="left"
          :idList="true"
          :return-array="false"
          :highlightFirst="true"
        />
      </template>
    </ImportTable>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import ProgressBar from 'primevue/progressbar'
import { useFields } from '@/components/importers/fields'
import { useImporter } from '@/components/importers/importer'
import { useImportSpreadsheet } from '@/components/importers/spreadsheet'
import { useGuesser } from '@/components/importers/guesser'
import * as xlsx from 'xlsx'
import Objects from '@/../imports/api/Objects'
import ImportUploader from '@/components/importers/steps/ImportUploader.vue'
import ImportMapper from '@/components/importers/steps/ImportMapper.vue'
import ImportTable from '@/components/importers/steps/ImportTable.vue'
import ImporterHeader from '@/components/importers/ImporterHeader.vue'

const { buildDefaultObject } = Objects

const props = defineProps({
  global: {
    default: false
  }
})

const store = useStore()
const { guessByType } = useGuesser()
const { items, importId, reset: resetImporter } = useImporter({ perPage: 20 })
const { supportedFields, visibleSupportedFields } = useFields('item')

const extendedFields = computed(() => {
  return [
    ...visibleSupportedFields.value,
    {
      header: 'Images',
      required: false,
      field: 'file_ids',
      extended: true
    }
  ]
})

const {
  workbook,
  sheetData,
  selectedSheet,
  uploadFileHandler,
  downloadImportTemplate,
  reset: resetImportSpreadsheet,
  globalVendorFieldFound,
  columnsMapToField
} = useImportSpreadsheet({
  global: props.global,
  schema: 'item'
})

const loadingStage = ref('')
const loading = computed(() => importing.value || processing.value)
const loadingProgress = computed(() => {
  if (amountToProcess.value) {
    return (processedItems.value / amountToProcess.value) * 100
  }

  return 0
})

const processing = ref(false)
const amountToProcess = ref(0)
const processedItems = ref(0)
const processingStage = ref('')
const importing = ref(false)

const existingItems = ref({})
const keyField = ref('cost_type_name')
const mappingKeySet = ref([
  {
    value: 'cost_type_name',
    text: 'Item name'
  },
  {
    value: 'cost_type_id',
    text: 'Item id (for updating existing items only)'
  },
  {
    value: 'cost_type_sku',
    text: 'SKU'
  }
])

async function exportItems() {
  const payload = {
    path: '/cost_type/export'
  }

  // if user is a super-user and exporting global catalog
  if (props.global && store.state.session.user.user_is_super_user) {
    payload.data = { global: true }
  }

  const { set } = await store.dispatch('ajax', payload)

  if (!set.length) {
    return store.dispatch('alert', {
      message: 'No items to export yet!',
      error: true
    })
  }

  const headings = Object.keys(set[0])

  const wb = xlsx.utils.book_new()

  const aoa = [headings, ...set.reduce((acc, vals) => [...acc, Object.values(vals)], [])]

  const ws = xlsx.utils.aoa_to_sheet(aoa)
  xlsx.utils.book_append_sheet(wb, ws, 'Items')
  xlsx.writeFile(wb, 'ItemExports.xlsx')

  return true
}

function reset() {
  resetImportSpreadsheet()
  resetImporter()
  existingItems.value = {}
  amountToProcess.value = 0
  processedItems.value = 0
  loading.value = false
  processing.value = false
  importing.value = false
}

async function importItems() {
  importing.value = true
  processedItems.value = 0
  loadingStage.value = 'Beginning import'
  const chunkSize = 20
  const chunks = _.chunk(items.value, chunkSize)
  amountToProcess.value = chunks.length
  let saved = null

  try {
    const preaudit = async (item) => {
      const price = item.cost_matrix_rate_net

      const { latestSet } = await store.dispatch('CostType/cascadeDependencies', {
        changes: { a: item },
        set: { a: item }
      })

      const audited = latestSet.a

      const { changes } = await store.dispatch('CostType/setPrice', {
        object: audited,
        price
      })

      const priced = {
        ...audited,
        ...changes
      }

      // Set image urls
      priced.aoImages = priced.image_urls
        ? c.makeArray(priced.image_urls || []).map((url) => ({
            file_url: String(url).trim(),
            file_name: String(url).trim()
          }))
        : null

      return priced
    }

    saved = await c.waterfall(
      chunks.map((chunk, index) => async () => {
        const startPos = index * chunkSize
        loadingStage.value = `Auditing batch ${startPos + 1} - ${startPos + chunk.length} of ${items.value.length}`
        const auditedItems = await Promise.all(chunk.map((item) => preaudit(item)))
        processedItems.value += 0.5
        loadingStage.value = `Updating items ${startPos + 1} - ${startPos + chunk.length} of ${items.value.length}`
        await store.dispatch('CostType/partialUpdate', {
          selected: auditedItems,
          alert: false
        })
        processedItems.value += 0.5
      })
    )

    await store.dispatch('alert', {
      message: `Imported/updated ${items.value.length} items!`
    })

    reset()
  } catch (e) {
    await store.dispatch('alert', {
      message: e.userMessage || 'There was an error. Contact support for assistance.',
      error: true
    })
    throw e
  } finally {
    importing.value = false
  }

  return saved
}

async function getExistingItems(keys) {
  if (!keys.length) return
  const chunkSize = 20
  const chunked = _.chunk(keys, chunkSize)

  const sets = await c.waterfall(
    chunked.map((chunk, index) => async () => {
      const filtered = chunk.filter((val) => !!val)
      const joined = filtered.join('||')
      if (!filtered.length) return []
      const startPos = index * chunkSize
      processingStage.value = `Searching existing items (${startPos + 1} - ${startPos + chunk.length} of ${keys.length})...`
      return (
        await store.dispatch('CostType/filter', {
          filters: {
            [keyField.value]: joined
          },
          limit: filtered.length
        })
      ).set
    })
  )

  existingItems.value = sets.reduce((acc, set) => [...acc, ...set], [])
}

function findExistingItemByImportedItem(importedItem) {
  const key = String(importedItem[keyField.value] || '')
    .toLowerCase()
    .trim()
  const found = existingItems.value.find(
    (ei) => String(ei[keyField.value]).toLowerCase().trim() === key
  )

  return found || {}
}

async function processItem(sheetItem) {
  // First map column values to actual fields
  const item = {}
  Object.keys(columnsMapToField.value).forEach((column) => {
    if (!columnsMapToField.value[column]) return // ignore

    item[columnsMapToField.value[column]] =
      typeof sheetItem[column] !== 'undefined' && sheetItem[column] !== null
        ? sheetItem[column]
        : ''
  })

  loadingStage.value = `Processing item: ${item.cost_type_name}`

  const original = _.immutable(item)

  const fieldsToSet = Object.keys(original).filter(
    (field) =>
      !/_net|_has_/.test(field) ||
      (String(original[field]).trim() !== '' &&
        String(original[field]).trim().toLowerCase() !== 'null')
  )

  const get10 = (val) => {
    if (val === 0 || val === false || val === 'false' || val === '0') return 0
    if (val === 1 || val === true || val === 'true' || val === '1') return 1
    if (+val > 0) return 1
    return 0
  }

  const laborCost = c.toNum(original.cost_matrix_labor_cost_net)
  const materialsCost = c.toNum(original.cost_matrix_materials_cost_net)
  const aggregateCost = c.toNum(original.cost_matrix_aggregate_cost_net)

  const hasLabor = get10(original.cost_type_has_labor || !c.eq(laborCost, 0))

  const hasMaterials = !hasLabor
    ? 1
    : get10(original.cost_type_has_materials || !c.eq(materialsCost, 0))

  const isSubcontracted = get10(original.cost_type_is_subcontracted)
  const price = c.toNum(original.cost_matrix_rate_net)

  const existing = findExistingItemByImportedItem(item)

  // console.log('existing', _.immutable(existing));
  const object = buildDefaultObject('cost_type', {
    ...existing,
    // Only set non-reactive text fields, everything else needs
    // to be specifically and individually set below
    ...Object.keys(item)
      .filter((field) => !/_has_|_is_|_net|_gross|_tax/.test(field))
      .reduce(
        (acc, field) => ({
          ...acc,
          [field]: item[field]
        }),
        {}
      ),

    // If there is no existing found, remove the id from the list
    ...(!Object.keys(existing).length ? { cost_type_id: '' } : {})
  })

  // Now set required values
  object.cost_type_import_id = importId.value
  object.cost_type_is_indexed = 0
  object.cost_matrix_markup_net = original.cost_matrix_markup_net || store.getters.defaultMarkup

  processingStage.value = 'Determining item vendor...'
  object.vendor_id = object.vendor_id
    ? await guessByType({
        phrase: object.vendor_id,
        type: 'vendor',
        filters: {
          company_id: store.state.session.scope.company || 'NULL'
        },
        createIfNotFound: async () => {
          // If we're adding global items, there shouldn't be any company-specific vendor
          if (props.global) return null

          const { object: vendor } = await store.dispatch('Vendor/save', {
            object: {
              type: 'vendor',
              company_name: object.vendor_id
            },
            go: false,
            alert: false
          })

          return vendor.vendor_id
        }
      })
    : null

  processingStage.value = 'Determining item parent...'
  object.parent_cost_type_id = object.parent_cost_type_id
    ? await guessByType({
        phrase: object.parent_cost_type_id,
        type: 'cost_type',
        exact: true,
        field: 'cost_type_name', // applies to exact
        filters: {
          company_id: store.state.session.scope.company || 'NULL'
        },
        createIfNotFound: async (name) => {
          const { object: cat } = await store.dispatch('CostType/save', {
            object: {
              type: 'cost_type',
              cost_type_is_parent: 1,
              cost_type_name: name,
              parent_cost_type_id: null
            },
            go: false,
            alert: false
          })

          return cat.cost_type_id
        }
      })
    : null

  processingStage.value = 'Determining item unit of measure...'
  object.unit_of_measure_id =
    (await guessByType({
      phrase: object.unit_of_measure_id || 'count',
      type: 'unit_of_measure'
    })) || 'count'

  processingStage.value = 'Determining item purchase unit of measure...'
  object.purchase_unit_of_measure_id =
    (await guessByType({
      phrase: object.purchase_unit_of_measure_id || object.unit_of_measure_id,
      type: 'unit_of_measure'
    })) || object.unit_of_measure_id

  processingStage.value = 'Determining item unit of weight...'
  object.weight_unit_of_measure_id =
    (await guessByType({
      phrase: object.weight_unit_of_measure_id || 'lbs',
      type: 'unit_of_measure'
    })) || 'lbs'

  object.cost_type_material_waste_factor_net = original.cost_type_material_waste_factor_net || 0

  object.cost_type_materials_purchase_qty_per_unit =
    original.cost_type_materials_purchase_qty_per_unit || null

  object.cost_type_manufacturer_name = original.cost_type_manufacturer_name || null

  object.cost_type_minimum_materials_cost_net =
    original.cost_type_minimum_materials_cost_net || null

  object.cost_type_minimum_labor_cost_net = original.cost_type_minimum_labor_cost_net || null

  object.cost_type_static_labor_cost_net = original.cost_type_static_labor_cost_net || null

  object.cost_type_static_materials_cost_net = original.cost_type_static_materials_cost_net || null

  processingStage.value = 'Matching item tax type...'
  object.tax_id = object.tax_id
    ? await guessByType({
        phrase: object.tax_id,
        type: 'tax'
      })
    : null

  processingStage.value = 'Matching item labor type...'
  object.labor_type_id = object.labor_type_id
    ? await guessByType({
        phrase: object.labor_type_id || null,
        type: 'labor_type',
        createIfNotFound: async () => {
          const { object: laborType } = await store.dispatch('LaborType/save', {
            object: {
              type: 'labor_type',
              labor_type_name: object.labor_type_id
            },
            go: false,
            alert: false
          })

          return laborType.labor_type_id
        }
      })
    : await store.dispatch('LaborType/getDefaultId', {})

  object.image_urls = original.image_urls || ''

  const tradeTypes = c.makeArray(store.state.session.company.trade_type_ids)
  const defaultStagePhrase = object.stage_id || (tradeTypes.length ? tradeTypes[0] : null)

  if (defaultStagePhrase) {
    processingStage.value = 'Matching item default stage...'
    object.stage_id = await guessByType({
      phrase: defaultStagePhrase,
      type: 'stage',
      fields: [
        {
          name: 'trade_type_name',
          weight: 3
        }
      ]
    })

    processingStage.value = 'Setting item stage...'
    const { changes } = await store.dispatch('CostType/setStage', {
      // store: storeName.value,
      stage: object.stage_id,
      confirm: false,
      object,
      useCache: true
    })

    object.stage_id = changes.stage_id
    object.stage_name = changes.stage_name
    object.trade_type_id = changes.trade_type_id
    object.trade_type_name = changes.trade_type_name
  }

  const defaultCsiCodePhrase = object.csi_code_id

  if (defaultCsiCodePhrase) {
    processingStage.value = 'Matching item CSI code...'
    object.csi_code_id = await guessByType({
      phrase: defaultCsiCodePhrase,
      type: 'csi_code',
      fields: [
        {
          name: 'csi_code_name',
          weight: 3
        }
      ]
    })
  }

  const common = {
    auditLocal: true,
    auditFull: false,
    store: 'CostType',
    object
  }

  const integrateChanges = (changes) => {
    common.object = {
      ...common.object,
      ...changes
    }
  }

  // Now progressively confirm pricing
  // Set has labor
  if (fieldsToSet.includes('cost_type_has_labor')) {
    processingStage.value = 'Determining whether item has labor...'
    integrateChanges(
      (
        await store.dispatch('CostType/setHasLabor', {
          ...common,
          hasLabor
        })
      ).changes
    )
  }

  if (!isSubcontracted && hasLabor && common.object.labor_type_id) {
    processingStage.value = 'Determining whether item has labor...'
    integrateChanges(
      (
        await store.dispatch('CostType/setLaborType', {
          ...common,
          laborType: common.object.labor_type_id,
          useCache: true
        })
      ).changes
    )
  }

  if (fieldsToSet.includes('cost_type_hours_per_unit')) {
    processingStage.value = 'Setting item hours per unit...'
    integrateChanges(
      (
        await store.dispatch('CostType/setHours', {
          ...common,
          hours: common.object.cost_type_hours_per_unit
        })
      ).changes
    )
  }

  // set has materials
  if (fieldsToSet.includes('cost_type_has_materials')) {
    processingStage.value = 'Determining whether item has materials...'
    integrateChanges(
      (
        await store.dispatch('CostType/setHasMaterials', {
          ...common,
          hasMaterials
        })
      ).changes
    )
    // console.log('after has mat', _.imm(common.object));
  }

  // set labor cost
  if (fieldsToSet.includes('cost_matrix_labor_cost_net')) {
    processingStage.value = 'Determining item labor cost...'
    integrateChanges(
      (
        await store.dispatch('CostType/setLaborCost', {
          ...common,
          laborCost,
          useCache: true
        })
      ).changes
    )
    // console.log('after lab', _.imm(common.object));
  }

  // set materials cost
  if (fieldsToSet.includes('cost_matrix_materials_cost_net')) {
    processingStage.value = 'Determining item material cost...'
    integrateChanges(
      (
        await store.dispatch('CostType/setMaterialsCost', {
          ...common,
          materialsCost
        })
      ).changes
    )
    // console.log('after mat', _.imm(common.object));
  }

  // set is subcontracted
  if (fieldsToSet.includes('cost_type_is_subcontracted')) {
    processingStage.value = 'Determining whether item is subcontracted...'
    integrateChanges(
      (
        await store.dispatch('CostType/setIsSubcontracted', {
          ...common,
          isSubcontracted
        })
      ).changes
    )
    // console.log('after is subc', _.imm(common.object));
  }

  // set aggregate cost
  if (fieldsToSet.includes('cost_matrix_aggregate_cost_net')) {
    // if the material and labour costs do not add up to the agg cost we must guess them
    const GuessLabourPercentage = materialsCost + laborCost !== aggregateCost
    processingStage.value = 'Guessing item aggregate cost...'
    integrateChanges(
      (
        await store.dispatch('CostType/setAggregateCost', {
          ...common,
          aggregateCost,
          GuessLabourPercentage
        })
      ).changes
    )
    // console.log('after agg', _.imm(common.object));
  }

  // set price
  if (fieldsToSet.includes('cost_matrix_rate_net')) {
    // console.log('before rate', _.imm(common.object));
    processingStage.value = 'Determining item cost matrix rate...'
    integrateChanges(
      (
        await store.dispatch('CostType/setPrice', {
          ...common,
          price
        })
      ).changes
    )
  }

  return common.object
}

async function processItems() {
  processing.value = true
  const userkeyindex = Object.values(columnsMapToField.value).indexOf(keyField.value)
  const userkeyfield = Object.keys(columnsMapToField.value)[userkeyindex]
  const keys = sheetData.value.map((sd) => sd[userkeyfield])
  amountToProcess.value = keys.length
  loadingStage.value = 'Fetching any existing items...'
  await getExistingItems(keys)

  const processors = sheetData.value.map((row) => async () => {
    const item = await processItem(row)
    processedItems.value += 1
    return item
  })

  // one at a time
  loadingStage.value = 'Matching up fetched items...'
  const localItems = await c.waterfall(processors)

  // delete ones without names
  items.value = localItems.filter((item) => item.cost_type_name)

  loadingStage.value = 'Reticulating splines...'
  processing.value = false
  amountToProcess.value = 0
  processedItems.value = 0

  return localItems
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>
